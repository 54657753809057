/**
 * Отображение картинки
 * При ошибке загрузки картинки - отображение заглушки
 */
import { useState, useEffect, useMemo, memo } from 'react';

import { testId } from 'modules/utils/test-id';
import fallbackImage from 'images/fallback-image.svg';

type Props = {
  alt: string;
  a11yLabel?: string;
  src: string | null;
  /**
   * Default: images/fallback-image.svg
   */
  fallback?: string;
  className?: string;
};

export const Image = memo((props: Props) => {
  const { alt, className, src, fallback = fallbackImage, a11yLabel } = props;

  const [source, setSource] = useState(src || fallback);
  const [isFallback, setIsFallback] = useState(!src);

  const testIdData = useMemo(() => {
    if (!a11yLabel) {
      return;
    }

    return testId(isFallback ? `${a11yLabel}-fallback` : a11yLabel);
  }, [a11yLabel, isFallback]);

  const onError = () => {
    setIsFallback(true);
  };

  useEffect(() => {
    if (isFallback) {
      setSource(fallback);
    }
  }, [fallback, isFallback]);

  useEffect(() => {
    if (typeof src === 'string') {
      setSource(src);
      setIsFallback(false);
    } else {
      setIsFallback(true);
    }
  }, [src]);

  return <img {...testIdData} className={className} src={source} alt={alt} onError={onError} />;
});
