import { memo } from 'react';

import { PageBodyWithTitle } from 'modules/components/common/page-body-with-title';
import { KPI_WEIGHTS_MAP } from 'modules/domain/earnings/constants';
import { calculateTotalKpi, getKPIEffect } from 'modules/domain/earnings/utils';
import { Loader } from 'modules/components/loader';
import { useEarningsInfo, useKpiInfo } from 'modules/domain/earnings/hooks';
import { useEarningsActualize } from 'modules/domain/earnings/hooks/use-earnings-actualize';
import { useStatsContext } from 'modules/components/stats-page/context';

import styles from './index.module.css';
import { KPIExplanationBlock } from './kpi-explanation-block';
import { KPIGroup } from './kpi-group';
import { SingleKPIIndicator } from './single-kpi-indicator';
import { TotalKPI } from './total-kpi';

export const KPIPage = memo(() => {
  const { selectedRange } = useStatsContext();

  useEarningsActualize(selectedRange, true);

  const earnings = useEarningsInfo(selectedRange);
  const kpiInfo = useKpiInfo(selectedRange);

  const totalKPI = calculateTotalKpi(kpiInfo);
  const kpiEffect = getKPIEffect(earnings);

  return (
    <div className={styles.container}>
      <PageBodyWithTitle title={'KPI'}>
        {!kpiInfo ? (
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        ) : (
          <>
            <KPIGroup
              title="Messages"
              subtitle="Send messages and increase reply rate to them"
              autotestId="kpi-group-messages"
            >
              <SingleKPIIndicator
                kpiNumbers={kpiInfo.textSent}
                weight={KPI_WEIGHTS_MAP.textSent}
                postfixMessage=" Sent"
                testIdPostfix="text-sent"
              />
              <SingleKPIIndicator
                kpiNumbers={kpiInfo.textReplies}
                weight={KPI_WEIGHTS_MAP.textReplies}
                postfixMessage="% RR"
                testIdPostfix="text-replies"
              />
            </KPIGroup>
            <KPIGroup
              title="Gifts"
              subtitle="Send gifts and increase reply rate to them"
              autotestId="kpi-group-gifts"
            >
              <SingleKPIIndicator
                kpiNumbers={kpiInfo.giftSent}
                weight={KPI_WEIGHTS_MAP.giftSent}
                postfixMessage=" Sent"
                testIdPostfix="gift-sent"
              />
              <SingleKPIIndicator
                kpiNumbers={kpiInfo.giftReplies}
                weight={KPI_WEIGHTS_MAP.giftReplies}
                postfixMessage="% RR"
                testIdPostfix="gift-replies"
              />
            </KPIGroup>

            <KPIGroup
              title="Photos"
              subtitle="Send photos and increase reply rate to them"
              autotestId="kpi-group-photos"
            >
              <SingleKPIIndicator
                kpiNumbers={kpiInfo.photoSent}
                weight={KPI_WEIGHTS_MAP.photoSent}
                postfixMessage=" Sent"
                testIdPostfix="photo-sent"
              />
              <SingleKPIIndicator
                kpiNumbers={kpiInfo.photoReplies}
                weight={KPI_WEIGHTS_MAP.photoReplies}
                postfixMessage="% RR"
                testIdPostfix="photo-replies"
              />
            </KPIGroup>

            <KPIGroup
              title="Response Speed"
              subtitle="Respond to clients' messages, photos and gifts at a quick pace"
              autotestId="kpi-group-response-speed"
            >
              <SingleKPIIndicator
                kpiNumbers={kpiInfo.quickReplies}
                weight={KPI_WEIGHTS_MAP.quickReplies}
                postfixMessage="% Messages answered within 1 minute"
                testIdPostfix="quick-replies"
              />
            </KPIGroup>
          </>
        )}
        {earnings && kpiInfo && <TotalKPI total={totalKPI} kpiEffect={kpiEffect} />}
        <KPIExplanationBlock />
      </PageBodyWithTitle>
    </div>
  );
});
