import { memo, useMemo } from 'react';

import { DialogMessage } from 'modules/domain/messages/model';
import { MessageRow } from 'modules/components/chat';

import { useDialogExtendedMessages } from './use-dialog-extended-messages';
import styles from './styles.module.scss';

type Props = {
  messages: DialogMessage[];
  currentDialogId: string;
  animatorId: string;
};

export const ChatMessagesList = memo((props: Props) => {
  const { messages, currentDialogId, animatorId } = props;

  const extendedMessages = useDialogExtendedMessages(messages, animatorId);

  const lastReadOutgoingMessage = useMemo(
    () => messages.find(message => message.outgoing && message.read),
    [messages],
  );

  return (
    <div className={styles.messagesContainer}>
      {extendedMessages.map(message => {
        return (
          <MessageRow
            key={message.tag}
            {...message}
            isLastReadOutgoingMessage={message.tag === lastReadOutgoingMessage?.tag}
            dialogId={currentDialogId}
          />
        );
      })}
    </div>
  );
});
