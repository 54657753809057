import { createContext } from 'react';

import { AuthState } from './types';

export type AuthContextType = {
  auth: AuthState;
  setAuthInfo: (operatorId: string, token: string, isPermanent?: boolean) => void;
  clearAuthInfo: () => void;
};

const initialValue: AuthContextType = {
  auth: {
    operatorId: undefined,
    token: undefined,
  },
  setAuthInfo: () => {},
  clearAuthInfo: () => {},
};

export const AuthContext = createContext<AuthContextType>(initialValue);
