import { TileContentFunc } from 'react-calendar';
import dayjs from 'dayjs';

import { CustomDateInput } from 'modules/utils/date/types';

import { SelectedDateCircle } from '../selected-date-circle';

export const getTileContent =
  (initialDate: CustomDateInput): TileContentFunc =>
  ({ date }) => {
    if (dayjs(date).isSame(initialDate, 'day')) {
      return <SelectedDateCircle />;
    }
    return null;
  };
