import { useCallback } from 'react';

import { useUserMediaApi } from 'modules/domain/media/hooks';
import { UserChatRole } from 'modules/constants';
import { toast } from 'modules/services';

import { useUserLifeStoryApi } from './use-user-life-story-api';
import { useUserApi } from './use-user-api';
import { useUserTagsApi } from './use-user-tags-api';
import { useUserInterestsApi } from './use-user-interests-api';

export const useLoadAllUserData = () => {
  const { loadLifeStory } = useUserLifeStoryApi();
  const { loadUserPhotos } = useUserMediaApi();
  const { loadUserData } = useUserApi();
  const { loadUserTags, loadUserExperimentsTags } = useUserTagsApi();
  const { loadUserInterests } = useUserInterestsApi();

  const loadAllUserData = useCallback(
    async (userId: string, role: UserChatRole) => {
      try {
        await loadUserData(userId);
        if (role === 'animator') {
          loadLifeStory(userId);
        }
        loadUserInterests(userId);
        loadUserTags(userId);
        loadUserExperimentsTags(userId);
        loadUserPhotos(userId);
      } catch (error) {
        toast.showError(`Failed to fetch ${role} data`);
      }
    },
    [
      loadLifeStory,
      loadUserData,
      loadUserExperimentsTags,
      loadUserInterests,
      loadUserTags,
      loadUserPhotos,
    ],
  );

  return loadAllUserData;
};
