import { useCallback } from 'react';

import { useConfig } from 'modules/config';
import { getPhotoUrl, GetPhotoUrlParams } from 'modules/utils';
import { useToken } from 'modules/domain/auth/hooks';

export type UseGetPhotoUrlParams = Omit<GetPhotoUrlParams, 'shardingConfig' | 'token'> & {
  isPrivate?: boolean;
};

export const useGetPhotoUrl = () => {
  const { sharding: shardingConfig } = useConfig();
  const token = useToken();

  return useCallback(
    ({ isPrivate, ...params }: UseGetPhotoUrlParams) =>
      getPhotoUrl({ ...params, shardingConfig, token: isPrivate ? token : undefined }),
    [shardingConfig, token],
  );
};
