import { memo } from 'react';
import isEqual from 'lodash/isEqual';

import { useCurrentDialogId } from 'modules/domain/dialog/hooks';
import { DialogNotes, DialogNotesList } from 'modules/domain/notes/model';
import { useDialogNotes, useDialogNotesApi } from 'modules/domain/notes/hooks';
import { useConfig } from 'modules/config';

import View from './view';

type Props = {
  type: keyof DialogNotes;
};

export const AccordionTabNotes = memo((props: Props) => {
  const { type } = props;

  const dialogId = useCurrentDialogId();
  const { maxNotesInTopic } = useConfig();
  const { updateDialogNotes } = useDialogNotesApi();
  const notes = useDialogNotes(dialogId);

  const userNotesList = notes?.[type];

  const onSave = (notesList: DialogNotesList) => {
    if (!isEqual(notesList, userNotesList)) {
      updateDialogNotes(dialogId, { ...notes!, [type]: notesList });
    }
  };

  if (!userNotesList) {
    return null;
  }

  return <View notes={userNotesList} onSave={onSave} maxNotesInTopic={maxNotesInTopic} />;
});
