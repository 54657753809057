import { useCallback } from 'react';

import { useApi } from 'modules/api';
import { useOperatorId } from 'modules/domain/auth/hooks';
import { useLogger } from 'modules/services';
import { useDispatchAction } from 'modules/hooks/redux';

import {
  mapFastAnswersDtoToList,
  mapFastAnswersListToDto,
  setFastAnswers as setFastAnswersAction,
  FastAnswersList,
} from '../model';

import { useFastAnswersAnalytics } from './use-fast-answers-analytics';

export const useFastAnswersApi = () => {
  const { operatorsFastAnswers: operatorsFastAnswersApi } = useApi();
  const { logError } = useLogger('useFastAnswersApi');
  const operatorId = useOperatorId();
  const { trackFastAnswersChanged } = useFastAnswersAnalytics();
  const setFastAnswers = useDispatchAction(setFastAnswersAction);

  const loadOperatorFastAnswers = useCallback(async () => {
    try {
      const fastAnswersDto = await operatorsFastAnswersApi.getFastAnswers(operatorId);
      const fastAnswers = mapFastAnswersDtoToList(fastAnswersDto);
      setFastAnswers({ data: fastAnswers });
    } catch (error) {
      logError('loadOperatorFastAnswers error', { error });
    }
  }, [operatorsFastAnswersApi, setFastAnswers, operatorId, logError]);

  const updateOperatorFastAnswers = useCallback(
    async (fastAnswersList: FastAnswersList) => {
      const payload = mapFastAnswersListToDto(fastAnswersList);

      trackFastAnswersChanged(payload);

      try {
        await operatorsFastAnswersApi.updateFastAnswers(operatorId, payload);

        setFastAnswers({ data: fastAnswersList });
      } catch (error) {
        logError('updateOperatorFastAnswers error', { error, payload });

        throw error;
      }
    },
    [operatorsFastAnswersApi, setFastAnswers, operatorId, trackFastAnswersChanged, logError],
  );

  return {
    loadOperatorFastAnswers,
    updateOperatorFastAnswers,
  };
};
