import { useState } from 'react';

import { AuthState, PersistedState } from '../types';

import { useApiAuthTokenActualizer } from './use-api-auth-token-actualizer';

const STORAGE_KEY = 'auth';

const defaultState: AuthState = {
  token: undefined,
  operatorId: undefined,
};

export function useAuthStorage() {
  const [authState, setAuthState] = useState<AuthState>(
    () =>
      parseJSON(sessionStorage.getItem(STORAGE_KEY)) ||
      parseJSON(localStorage.getItem(STORAGE_KEY)) ||
      defaultState,
  );

  useApiAuthTokenActualizer(authState.token);

  const setAuthInfo = (operatorId: string, token: string, isPermanent?: boolean) => {
    const storage = isPermanent ? localStorage : sessionStorage;

    storage.setItem(STORAGE_KEY, JSON.stringify({ id: operatorId, token } as PersistedState));

    setAuthState({ operatorId, token });
  };

  const clearAuthInfo = () => {
    [localStorage, sessionStorage].forEach(storage => storage.removeItem(STORAGE_KEY));
    setAuthState(defaultState);
  };

  return {
    auth: authState,
    setAuthInfo,
    clearAuthInfo,
  };
}

function parseJSON(json: string | null): AuthState | null {
  try {
    const parsedJson = json && JSON.parse(json);

    if (parsedJson?.token && parsedJson?.id) {
      return {
        token: parsedJson.token,
        operatorId: parsedJson.id,
      };
    }
  } catch (e) {
    // no need to handle this error
  }
  return null;
}
