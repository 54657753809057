import { PropsWithChildren } from 'react';

import { useChatScrollerContext } from 'modules/components/chat/chat-scroller-context';

import styles from './styles.module.scss';

type Props = PropsWithChildren<unknown>;

export const ChatScrollContainer = (props: Props) => {
  const { children } = props;

  const { setScrollContainerRef, setScrollContentRef } = useChatScrollerContext();

  return (
    <div key="chat-scroll-container" className={styles.scrollContainer} ref={setScrollContainerRef}>
      <div className={styles.scrollContent} ref={setScrollContentRef}>
        {children}
      </div>
    </div>
  );
};
