import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OperatorInfo, OperatorCommissionInfo, OperatorModelState } from './types';

const initialState: OperatorModelState = {
  info: null,
  commission: null,
  needOnboarding: false,
  dialogSearchEnabled: false,
};

export const operatorModel = createSlice({
  name: 'operator',
  initialState,
  reducers: {
    setOperatorInfo: (state, { payload }: PayloadAction<OperatorInfo>) => {
      state.info = payload;
    },
    setOperatorCommissionInfo: (state, { payload }: PayloadAction<OperatorCommissionInfo>) => {
      state.commission = payload;
    },
    setOperatorNeedOnboarding: (state, { payload }: PayloadAction<{ needOnboarding: boolean }>) => {
      state.needOnboarding = payload.needOnboarding;
    },
    setDialogSearchEnabled: (state, { payload }: PayloadAction<{ enabled: boolean }>) => {
      state.dialogSearchEnabled = payload.enabled;
    },
  },
  selectors: {
    getOperatorInfo: state => state.info,
    getOperatorCommissionInfo: state => state.commission,
    getOperatorNeedOnboarding: state => state.needOnboarding,
    getDialogSearchEnabled: state => state.dialogSearchEnabled,
  },
});
