import { PropsWithChildren } from 'react';

import { testId } from 'modules/utils/test-id';

import styles from './index.module.css';

type Props = PropsWithChildren<{ title: string; subtitle: string; autotestId: string }>;

export const KPIGroup = (props: Props) => {
  const { title, subtitle, children, autotestId } = props;

  return (
    <div className={styles.container} {...testId(autotestId)}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>
      <div className={styles.indicators}>{children}</div>
    </div>
  );
};
