import styles from './index.module.css';
import { PreviousIcon } from './previous-icon';
import { NextIcon } from './next-icon';

type Props = {
  onClickPrev: () => void;
  onClickNext: () => void;
  currentDate: string;
  showNextButton: boolean;
  showPrevButton: boolean;
};
export const CalendarNavigation = (props: Props) => {
  const { onClickNext, onClickPrev, showPrevButton, showNextButton, currentDate } = props;

  return (
    <div className={styles.navigation}>
      {showPrevButton ? (
        <PreviousIcon onClick={onClickPrev} />
      ) : (
        <div className={styles.iconPlaceholder} />
      )}
      <div className={styles.currentDate}>{currentDate}</div>
      {showNextButton ? (
        <NextIcon onClick={onClickNext} />
      ) : (
        <div className={styles.iconPlaceholder} />
      )}
    </div>
  );
};
