import { memo } from 'react';

import { useBaseTabsList } from 'modules/components/accordion-tabs/hooks';
import { useSpotifyMatchResult } from 'modules/domain/spotify-match/hooks';

import { AccordionTabSpotifyMatch } from '../sections/spotify-match';
import { TabsList } from '../tabs-list';

type Props = {
  animatorId: string;
  attendeeId: string;
};

export const AttendeeTabs = memo((props: Props) => {
  const { animatorId, attendeeId } = props;

  const spotifyMatchResult = useSpotifyMatchResult(animatorId, attendeeId);

  const baseTabs = useBaseTabsList(animatorId, attendeeId, 'attendee');

  const tabs = baseTabs.concat({
    id: 'spotify-match',
    title: 'Spotify',
    enabled: !!spotifyMatchResult,
    render: () => (
      <AccordionTabSpotifyMatch attendeeId={attendeeId} matchResult={spotifyMatchResult!} />
    ),
  });

  return <TabsList tabs={tabs} type="attendee" />;
});
