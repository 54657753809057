import { IHttpTransport } from 'modules/api/transport';

import { IDictionariesDomain } from './contracts';
import { DictionaryName, DictionaryDto } from './dto';

/**
 * These are supported languages at the moment
 */
const supportedLanguagesMap: Record<string, string> = {
  en: 'EN-en',
  fr: 'FR-fr',
  de: 'DE-de',
};

export class DictionariesDomain implements IDictionariesDomain {
  constructor(
    private readonly transport: IHttpTransport,
    private readonly realm: string,
  ) {}

  getDictionary(name: DictionaryName, lang: string): Promise<DictionaryDto> {
    return this.transport
      .get<DictionaryDto>(`/dict/${name}`, {
        params: { realm: this.realm },
        headers: {
          'Accept-Language': supportedLanguagesMap[lang] || supportedLanguagesMap.en,
        },
      })
      .then(response => response.data);
  }
}
