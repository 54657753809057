import { useCallback } from 'react';

import { useApi } from 'modules/api';
import { useLogger } from 'modules/services';
import { useDispatchAction } from 'modules/hooks/redux';

import { mapUserDtoToUser, setUser as setUserAction } from '../model';

export const useUserApi = () => {
  const { users: usersApi } = useApi();
  const { logError } = useLogger('useUserApi');
  const setUser = useDispatchAction(setUserAction);

  const loadUserData = useCallback(
    async (userId: string) => {
      try {
        const userDto = await usersApi.getUserData(userId);

        const user = mapUserDtoToUser(userDto);

        setUser(user);
      } catch (error) {
        logError('fetchUserData error', { error, userId });

        throw error;
      }
    },
    [setUser, usersApi, logError],
  );

  return {
    loadUserData,
  };
};
