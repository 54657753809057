import { useRef } from 'react';

import { useApi } from 'modules/api';

export const useApiAuthTokenActualizer = (token: string | undefined) => {
  const api = useApi();

  const prevToken = useRef<string>();

  if (prevToken.current !== token) {
    if (token) {
      api.setAuthToken(token);
    } else {
      api.clearAuthToken();
    }

    prevToken.current = token;
  }
};
