import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './styles.css';

export const Notification = () => {
  return (
    <ToastContainer
      position="top-center"
      autoClose={3000}
      newestOnTop={false}
      closeButton={false}
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
    />
  );
};
