import { useNavigate } from 'react-router-dom';

import closeIcon from 'images/icon-close-big.svg';
import { testId } from 'modules/utils';
import { AppRoutes } from 'modules/constants';
import { IconButton } from 'modules/components/common/button';

import styles from './index.module.css';

export const CloseButton = () => {
  const navigate = useNavigate();

  return (
    <IconButton
      onClick={() => navigate(AppRoutes.Chat)}
      className={styles.closeButton}
      {...testId('earnings-page-header-close-button')}
    >
      <img src={closeIcon} alt="close" />
    </IconButton>
  );
};
