import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Dialog, DialogModelState } from './types';

type SetDialogAction = PayloadAction<{ dialog: Dialog; unansweredDialogsCount: number }>;
type SetFoundDialogAction = PayloadAction<{ dialog: Dialog }>;

const initialState: DialogModelState = {
  currentDialog: null,
  foundDialog: null,
  dialogsQueueIsEmpty: false,
  unansweredDialogsCount: 0,
};

export const dialogModel = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    setCurrentDialog: (state, action: SetDialogAction) => {
      const { dialog, unansweredDialogsCount } = action.payload;
      state.currentDialog = dialog;
      state.foundDialog = initialState.foundDialog;
      state.unansweredDialogsCount = unansweredDialogsCount;
    },
    setDialogsQueueIsEmpty: state => {
      state.dialogsQueueIsEmpty = true;
    },
    setFoundDialog: (state, action: SetFoundDialogAction) => {
      const { dialog } = action.payload;
      state.foundDialog = dialog;
    },
    resetFoundDialog: state => {
      state.foundDialog = initialState.foundDialog;
    },
    resetDialogState: () => initialState,
  },
  selectors: {
    getCurrentDialog: state => state.currentDialog,
    getFoundDialog: state => state.foundDialog,
    getDialogsQueueIsEmpty: state => state.dialogsQueueIsEmpty,
    getUnansweredDialogsCount: state => state.unansweredDialogsCount,
  },
});
