import { useCallback } from 'react';

import { useOperatorApi } from 'modules/domain/operator/hooks';
import { useOperatorId } from 'modules/domain/auth/hooks';
import { useConfig } from 'modules/config';
import { usePoller } from 'modules/hooks';

export const useOperatorActivitySender = () => {
  const operatorId = useOperatorId();
  const { sendActivityStatus } = useOperatorApi();
  const { operatorActivitySenderInterval } = useConfig();

  const sendOperatorActivityStatus = useCallback(() => {
    sendActivityStatus(operatorId);
  }, [sendActivityStatus, operatorId]);

  usePoller(sendOperatorActivityStatus, operatorActivitySenderInterval);
};
