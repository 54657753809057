import { memo } from 'react';
import cn from 'classnames';

import { useCanToggleExplicit } from 'modules/domain/preferences/hooks';
import { DayIndicator } from 'modules/components/day-indicator';

import { ExplicitBanner } from './explicit-banner';
import styles from './styles.module.scss';

type Props = {
  dialogId: string;
  timestamp: number | undefined;
};

export const ChatHeader = memo((props: Props) => {
  const { dialogId, timestamp } = props;

  const canToggleExplicit = useCanToggleExplicit(dialogId);

  return (
    <div className={styles.header}>
      {!!timestamp && (
        <div className={cn(styles.item, styles.center)}>
          <DayIndicator timestamp={timestamp} onTop />
        </div>
      )}
      {canToggleExplicit && (
        <div className={cn(styles.item, styles.right)}>
          <ExplicitBanner dialogId={dialogId} />
        </div>
      )}
    </div>
  );
});
