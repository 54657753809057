import cn from 'classnames';

import { testId } from 'modules/utils/test-id';

import styles from './index.module.scss';

type Props = {
  value: number;
};

export const KPIValue = (props: Props) => {
  const { value } = props;

  const isPositive = value > 0;

  return (
    <div
      className={cn(styles.value, isPositive ? styles.goodValue : styles.badValue)}
      {...testId('kpi-scale-value')}
    >
      {isPositive ? '+' : ''}
      {value}%
    </div>
  );
};
