import { useCallback, useEffect, useRef, useState } from 'react';

import { useCurrentDialogId } from 'modules/domain/dialog/hooks';
import { parseDialogId } from 'modules/utils';
import { toast } from 'modules/services';
import { Unsubscribe } from 'modules/domain/common/types';

import { AudioPlayer, AudioFileStatus, IAudioFile, AudioEvent } from '../audio-player';

import { useAudioMessagesApi } from './use-audio-messages-api';

type AudioMessageFileState = {
  status: AudioFileStatus;
  downloadProgress: number;
  src: string;
};

type AudioMessageFileStateWithControls = AudioMessageFileState & {
  play: () => void;
  pause: () => void;
};

export const useAudioMessagePlayer = (
  basename: string,
  outgoing?: boolean,
): AudioMessageFileStateWithControls => {
  const dialogId = useCurrentDialogId();
  const { downloadAnimatorAudioFile, downloadAttendeeAudioFile } = useAudioMessagesApi();

  const audioFileRef = useRef<IAudioFile | null>(null);
  const eventListenerUnsubscribe = useRef<Unsubscribe>();

  const [state, setState] = useState<AudioMessageFileState>({
    status: AudioFileStatus.pending,
    downloadProgress: 0,
    src: '',
  });

  useEffect(() => {
    if (state.status === AudioFileStatus.error) {
      toast.showError(
        'Failed to play an audio message. Please check your connection and try again later',
      );
    }
  }, [state.status]);

  useEffect(
    () => () => {
      eventListenerUnsubscribe.current?.();
      audioFileRef.current?.stop();
    },
    [],
  );

  const initAudioFile = useCallback(() => {
    const loadFile = async () => {
      const { animatorId, attendeeId } = parseDialogId(dialogId);

      const { url } = await (outgoing ? downloadAnimatorAudioFile : downloadAttendeeAudioFile)({
        animatorId,
        attendeeId,
        basename,
        onDownloadProgress: progress => {
          setState(prev => ({
            ...prev,
            downloadProgress: progress,
          }));
        },
      });

      return url;
    };

    const setStatus = (status: AudioFileStatus) => setState(prev => ({ ...prev, status }));

    const audioFile = AudioPlayer.shared().addAudio(basename, loadFile);

    setStatus(audioFile.status);

    eventListenerUnsubscribe.current = audioFile.addEventListener(
      AudioEvent.StatusChange,
      ({ status }) => {
        setStatus(status);
      },
    );

    audioFileRef.current = audioFile;
  }, [dialogId, basename, downloadAnimatorAudioFile, downloadAttendeeAudioFile, outgoing]);

  const play = useCallback(() => {
    if (!audioFileRef.current) {
      initAudioFile();
    }
    audioFileRef.current?.play();
  }, [initAudioFile]);

  const pause = useCallback(() => {
    audioFileRef.current?.pause();
  }, []);

  return {
    ...state,
    play,
    pause,
  };
};
