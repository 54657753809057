import { Calendar, TileContentFunc, TileDisabledFunc, TileClassNameFunc } from 'react-calendar';

import { formatShortWeekday } from './helpers';

type Props = {
  onChange: (date: Date) => void;
  tileDisabled: TileDisabledFunc;
  tileClassName?: TileClassNameFunc;
  tileContent: TileContentFunc;
  activeStartDate: Date;
};

export const CalendarWrapper = (props: Props) => {
  const { onChange, tileDisabled, tileClassName, tileContent, activeStartDate } = props;

  return (
    <Calendar
      formatShortWeekday={formatShortWeekday}
      onChange={date => onChange(date as Date)}
      tileDisabled={tileDisabled}
      tileClassName={tileClassName}
      tileContent={tileContent}
      view="month"
      showNavigation={false}
      activeStartDate={activeStartDate}
    />
  );
};
