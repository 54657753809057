import { ComponentProps } from 'react';

import { LabelItem } from '../label-item';

import styles from './styles.module.scss';

export type LabelItemProps = ComponentProps<typeof LabelItem> & {
  enabled: boolean;
};

type Props = {
  labels: LabelItemProps[];
};

export const LabelsList = (props: Props) => {
  const { labels } = props;

  return (
    <div className={styles.labels}>
      {labels
        .filter(l => l.enabled)
        .map(({ id, textTemplateParam }) => (
          <LabelItem key={id} id={id} textTemplateParam={textTemplateParam} />
        ))}
    </div>
  );
};
