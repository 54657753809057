import { Image } from 'modules/components/image';
import { useUserThumbnailUrl } from 'modules/hooks';

type Props = {
  ownerId: string;
  className?: string;
};

export const UserThumbnail = (props: Props) => {
  const { ownerId, className } = props;

  const { url, fallback, alt } = useUserThumbnailUrl(ownerId);

  return <Image fallback={fallback} src={url} className={className} alt={alt} />;
};
