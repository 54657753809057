import { memo } from 'react';

import { useBaseTabsList } from 'modules/components/accordion-tabs/hooks';
import { useLocalizedUserData } from 'modules/domain/users/hooks';

import { AccordionTabSentPhotos } from '../sections/sent-photos';
import { AccordionTabLifeStory } from '../sections/life-story';
import { TabsList } from '../tabs-list';

type Props = {
  animatorId: string;
  attendeeId: string;
};

export const AnimatorTabs = memo((props: Props) => {
  const { animatorId, attendeeId } = props;

  const localizedAnimator = useLocalizedUserData(animatorId);
  const animatorLifeStory = localizedAnimator?.info.lifeStory;

  const baseTabs = useBaseTabsList(animatorId, attendeeId, 'animator');

  const tabs = baseTabs.concat(
    {
      id: 'story',
      title: 'Life Story',
      enabled: !!animatorLifeStory,
      render: () => (
        <AccordionTabLifeStory lifeStory={animatorLifeStory!} animatorId={animatorId} />
      ),
    },
    {
      id: 'sentPhotos',
      title: 'Sent Photos',
      enabled: true,
      render: () => <AccordionTabSentPhotos animatorId={animatorId} />,
    },
  );

  return <TabsList tabs={tabs} type="animator" />;
});
