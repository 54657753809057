import { BackChevronIcon, ForwardChevronIcon } from 'modules/components/icon';
import { FastAnswersBaseButton } from 'modules/components/fast-answers/base-button';

import styles from './index.module.scss';

type Props = {
  direction: 'next' | 'prev';
  onClick: () => void;
};

export const FastAnswersSliderSwitchButton = (props: Props) => {
  const { direction, onClick } = props;

  return (
    <FastAnswersBaseButton className={styles.button} onClick={onClick}>
      {direction === 'next' ? <ForwardChevronIcon /> : <BackChevronIcon />}
    </FastAnswersBaseButton>
  );
};
