import { useEffect, useState } from 'react';

import { useDictionaryApi } from 'modules/domain/dictionaries/hooks';
import { useDialogLoadApi } from 'modules/domain/dialog/hooks';
import { useLoadOperatorData } from 'modules/domain/operator/hooks';
import { useOperatorId } from 'modules/domain/auth/hooks';

export const useAppDataInitializer = () => {
  const [initialized, setInitialized] = useState(false);

  const operatorId = useOperatorId();

  const { loadAllDictionaries } = useDictionaryApi();
  const { loadNextDialog } = useDialogLoadApi();
  const loadOperatorData = useLoadOperatorData();

  useEffect(() => {
    loadOperatorData(operatorId).then(operator => {
      if (operator) {
        setInitialized(true);

        loadNextDialog(operatorId);
        loadAllDictionaries(operator.language);
      }
    });
  }, [loadOperatorData, loadNextDialog, loadAllDictionaries, operatorId]);

  return initialized;
};
