import { useCallback } from 'react';

import { useApi } from 'modules/api';
import { useOperatorId } from 'modules/domain/auth/hooks';

export const useMediaAnalytics = () => {
  const { annals } = useApi();
  const operatorId = useOperatorId();

  const trackAllLibraryMediaSent = useCallback(
    (animatorId: string, attendeeId: string, basename: string) => {
      const payload = {
        'operator-id': operatorId,
        'animator-id': animatorId,
        'client-id': attendeeId,
        basename,
      };
      return annals.send(operatorId, 'multichat-sent-last-labrary-media', payload);
    },
    [annals, operatorId],
  );

  return { trackAllLibraryMediaSent };
};
