import { memo } from 'react';

import { AudioPlayButton } from 'modules/components/chat/audio/play-button';
import { useAudioMessagePlayer } from 'modules/domain/audio/hooks';
import { AudioMessageTemplate } from 'modules/domain/audio/model';
import { AudioFileStatus } from 'modules/domain/audio/audio-player';

import styles from './styles.module.scss';

type Props = Pick<AudioMessageTemplate, 'text' | 'basename'> & {
  onSendClick: () => void;
};

export const AudioMessageItem = memo((props: Props) => {
  const { text, basename, onSendClick } = props;

  const { play, pause, status, downloadProgress } = useAudioMessagePlayer(basename, true);

  const handlePlayClick = () => {
    status === AudioFileStatus.playing ? pause() : play();
  };

  return (
    <div className={styles.itemContainer}>
      <p>{text}</p>
      <div className={styles.buttonsRow}>
        <AudioPlayButton
          progress={downloadProgress}
          status={status}
          onClick={handlePlayClick}
          appearance="primary"
        />
        <button className={styles.sendBtn} onClick={onSendClick}>
          Send
        </button>
      </div>
    </div>
  );
});
