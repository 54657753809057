import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import calendar from 'dayjs/plugin/calendar';
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(utc);
dayjs.extend(calendar);
dayjs.extend(isoWeek);
