import { memo } from 'react';
import classNames from 'classnames';

import { AuthForm } from 'modules/components/auth/auth-form';
import { AnimatedLogo } from 'modules/components/animated-logo';

import styles from './styles.module.scss';

export const AuthPage = memo(() => {
  return (
    <div className={classNames(styles.authPage, 'h-100')}>
      <AnimatedLogo />
      <h1 className={styles.title}>Sign In to Multichat</h1>
      <AuthForm />
    </div>
  );
});
