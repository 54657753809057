import { Button } from 'modules/components/common/button/base';
import { testId } from 'modules/utils/test-id';
import { useGetPhotoUrl } from 'modules/hooks';

import styles from './index.module.scss';

type Props = {
  id: string;
  baseUrl: string;
  disabled: boolean;
  onItemClick: () => void;
};

export const MediaItem = (props: Props) => {
  const { id, baseUrl, disabled, onItemClick } = props;

  const getPhotoUrl = useGetPhotoUrl();

  const thumbnail = getPhotoUrl({
    baseUrl,
    size: Math.round(160 * window.devicePixelRatio),
    isPrivate: true,
  });

  return (
    <div className={styles.listItem} {...testId(`send-library-media-${id}`)}>
      <img className={styles.photo} src={thumbnail} />
      <Button type="button" size="sm" theme="green" disabled={disabled} onClick={onItemClick}>
        Send
      </Button>
    </div>
  );
};
