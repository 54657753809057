import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from 'modules/api';
import { useLogger } from 'modules/services';
import { UserExperimentTag } from 'modules/domain/users/model';

import { mapOperatorCommissionDto, mapOperatorDto } from '../adapter';
import { setOperatorInfo, setOperatorCommissionInfo, setDialogSearchEnabled } from '../model';

export const useOperatorApi = () => {
  const { operators: operatorsApi, usersTags: usersTagsApi } = useApi();
  const { logError } = useLogger('useOperatorApi');
  const dispatch = useDispatch();

  const sendActivityStatus = useCallback(
    async (operatorId: string) => {
      try {
        await operatorsApi.sendActivityStatus(operatorId);
      } catch (error) {
        logError('sendActivityStatus error', { error });
      }
    },
    [operatorsApi, logError],
  );

  const loadOperatorInfo = useCallback(
    async (operatorId: string) => {
      try {
        const operatorDto = await operatorsApi.getOperator(operatorId);

        const operator = mapOperatorDto(operatorDto);
        dispatch(setOperatorInfo(operator));
      } catch (error) {
        logError('Fetch operator info error', { error });
      }
    },
    [operatorsApi, logError, dispatch],
  );

  const loadOperatorCommissionInfo = useCallback(
    async (operatorId: string) => {
      try {
        const commissionDto = await operatorsApi.getOperatorCommission(operatorId);

        const commission = mapOperatorCommissionDto(commissionDto);
        dispatch(setOperatorCommissionInfo(commission));
      } catch (error) {
        logError('Fetch operator commission error', { error });
      }
    },
    [operatorsApi, logError, dispatch],
  );

  const loadDialogSearchEnabled = useCallback(
    async (operatorId: string) => {
      try {
        const tags = await usersTagsApi.getUserExperimentsTags(operatorId);

        const dialogSearchEnabled = tags.includes(UserExperimentTag.DialogSearchByIdEnabled);
        dispatch(setDialogSearchEnabled({ enabled: dialogSearchEnabled }));
      } catch (error) {
        logError('Load dialog search enabled error', { error });
      }
    },
    [usersTagsApi, logError, dispatch],
  );

  return {
    sendActivityStatus,
    loadOperatorInfo,
    loadOperatorCommissionInfo,
    loadDialogSearchEnabled,
  };
};
