import { memo } from 'react';

import { SendComplaintForm } from './form';
import styles from './index.module.scss';

export const SendComplaintModal = memo(() => {
  return (
    <div>
      <div className={styles.content}>
        <div className={styles.title}>Are you sure?</div>
        <div className={styles.subtitle}>Message will be checked on prohibited content.</div>
        <SendComplaintForm />
      </div>
    </div>
  );
});
