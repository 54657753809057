import { FastAnswerIcon } from 'modules/components/icon';
import { testId } from 'modules/utils/test-id';

import { FastAnswersBaseButton } from '../base-button';

import styles from './index.module.scss';

type Props = {
  variant: 'default' | 'extended';
  onClick: () => void;
};

export const FastAnswersAddButton = (props: Props) => {
  const { variant, onClick } = props;

  return (
    <FastAnswersBaseButton
      {...testId('add-template-button')}
      className={styles.button}
      onClick={onClick}
    >
      <FastAnswerIcon />
      {variant === 'extended' && <span>Click here to add Templates</span>}
    </FastAnswersBaseButton>
  );
};
