import { useEffect } from 'react';

import { useUserMediaApi } from 'modules/domain/media/hooks';
import { useDialogNotesApi } from 'modules/domain/notes/hooks';
import { useDialogPreferencesApi } from 'modules/domain/preferences/hooks';
import { useLoadAllUserData } from 'modules/domain/users/hooks';

export const useDialogDataLoader = (animatorId: string, attendeeId: string) => {
  const { loadDialogNotes } = useDialogNotesApi();
  const { loadAnimatorPreferences, loadAnimatorStoryTold } = useDialogPreferencesApi();
  const { loadUserPhotos } = useUserMediaApi();
  const loadAllUserData = useLoadAllUserData();

  useEffect(() => {
    loadAllUserData(animatorId, 'animator');
    loadAllUserData(attendeeId, 'attendee');

    loadDialogNotes(animatorId, attendeeId);
    loadAnimatorPreferences(animatorId, attendeeId);
    loadAnimatorStoryTold(animatorId, attendeeId);
  }, [
    animatorId,
    attendeeId,
    loadAnimatorPreferences,
    loadDialogNotes,
    loadAnimatorStoryTold,
    loadUserPhotos,
    loadAllUserData,
  ]);
};
