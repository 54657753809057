import { memo } from 'react';

import { Centerer } from 'modules/components/common/centerer';

import { CloseButton } from './close-button';
import { PageSwitcher } from './page-switcher';
import { DateRangesSwitcher } from './date-ranges-switcher';
import styles from './index.module.css';

export const StatsPageHeader = memo(() => {
  return (
    <div className={styles.header}>
      <Centerer>
        <PageSwitcher />
        <DateRangesSwitcher />
      </Centerer>
      <CloseButton />
    </div>
  );
});
