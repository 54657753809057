import { memo } from 'react';
import classNames from 'classnames';

import { MultichatHeader } from 'modules/components/multichat/multichat-header';
import { MultichatFooter } from 'modules/components/multichat/multichat-footer';
import { MultichatWindow } from 'modules/components/multichat/multichat-window';
import { AnimatedLogo } from 'modules/components/animated-logo';
import { Modal } from 'modules/components/common/modal';
import { ChatScrollerContextProvider } from 'modules/components/chat/chat-scroller-context';
import { useAppDataInitializer } from 'modules/domain/app/hooks';

import styles from './index.module.scss';

export const MultichatPage = memo(() => {
  const initialized = useAppDataInitializer();

  if (!initialized) {
    return (
      <div className={styles.loaderWrapper}>
        <AnimatedLogo />
      </div>
    );
  }

  return (
    <ChatScrollerContextProvider>
      <div className={classNames(styles.container, 'h-100')}>
        <MultichatHeader />
        <MultichatWindow />
        <MultichatFooter />
      </div>
      <Modal />
    </ChatScrollerContextProvider>
  );
});
