import { IHttpTransport } from 'modules/api/transport/http.contracts';

import {
  GetAutoGeneratedMessageAnswersPayload,
  NotifyAboutTheTrapPayload,
  IDialogsMessagesAutomationDomain,
} from './contracts';
import { AutoGeneratedAnswersDto } from './dto';

export class DialogsMessagesAutomationDomain implements IDialogsMessagesAutomationDomain {
  constructor(
    private readonly transport: IHttpTransport,
    private readonly realm: string,
  ) {}

  getAutoGeneratedMessageAnswers(
    payload: GetAutoGeneratedMessageAnswersPayload,
  ): Promise<AutoGeneratedAnswersDto> {
    const { animatorId, attendeeId, operatorId, messageId } = payload;
    return this.transport
      .get<AutoGeneratedAnswersDto>(
        `/multichat/dialogs/${operatorId}/${animatorId}/${attendeeId}/${messageId}/options`,
        {
          timeout: 30000,
        },
      )
      .then(response => response.data);
  }

  notifyAboutTheTrap(payload: NotifyAboutTheTrapPayload): Promise<void> {
    const { animatorId, attendeeId, operatorId, trapText } = payload;
    return this.transport
      .post<void>(
        `/multichat/operators/${operatorId}/trapped`,
        { clientId: attendeeId, animatorId, trapText },
        {
          params: { realm: this.realm },
        },
      )
      .then(response => response.data);
  }
}
