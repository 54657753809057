import { utcDate, formatDate } from 'modules/utils';

import { DatesRange } from '../model/types';

export const DEFAULT_DATES_RANGE = DatesRange.ThisWeek;

type DatesRangeItem = {
  title: string;
  from: string;
  to: string;
  id: DatesRange;
};

/*
 * It is not a static object cause the ranges should be recalculated if the day has changed
 * todo probably should make it explicit
 * */

export const getDatesRangeMap = (): Record<DatesRange, DatesRangeItem> => {
  const currentDate = utcDate();

  const startOfWeek = currentDate.startOf('isoWeek');
  const startOfMonth = currentDate.startOf('month');

  const startOfPrevWeek = startOfWeek.subtract(7, 'days');
  const startOfPrevMonth = startOfMonth.subtract(1, 'month');

  const endOfPrevWeek = startOfPrevWeek.endOf('isoWeek');
  const endOfPrevMonth = startOfPrevMonth.endOf('month');

  return {
    [DatesRange.ThisWeek]: {
      title: 'This week',
      from: formatDate(startOfWeek),
      to: formatDate(currentDate),
      id: DatesRange.ThisWeek,
    },
    [DatesRange.LastWeek]: {
      title: 'Last week',
      from: formatDate(startOfPrevWeek),
      to: formatDate(endOfPrevWeek),
      id: DatesRange.LastWeek,
    },
    [DatesRange.ThisMonth]: {
      title: 'This month',
      from: formatDate(startOfMonth),
      to: formatDate(currentDate),
      id: DatesRange.ThisMonth,
    },
    [DatesRange.LastMonth]: {
      title: 'Last month',
      from: formatDate(startOfPrevMonth),
      to: formatDate(endOfPrevMonth),
      id: DatesRange.LastMonth,
    },
  };
};
