import classNames from 'classnames';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

import { IconButton } from 'modules/components/common/button';
import { AudioFileStatus } from 'modules/domain/audio/audio-player';
import playIcon from 'images/play-icon.svg';
import pauseIcon from 'images/pause-icon.svg';

import styles from './styles.module.scss';

type Props = {
  appearance: 'primary' | 'secondary';
  onClick: () => void;
  status: AudioFileStatus;
  progress: number;
};

const progressStyles = buildStyles({
  trailColor: 'rgba(255,255,255,0.3)',
  pathColor: '#fff',
  strokeLinecap: 'butt',
});

export const AudioPlayButton = (props: Props) => {
  const { onClick, status, appearance, progress } = props;

  const renderIcon = () => {
    switch (status) {
      case AudioFileStatus.playing:
        return <img src={pauseIcon} />;

      case AudioFileStatus.loading:
        return (
          <div className={styles.progressBarWrapper}>
            <CircularProgressbar value={progress} strokeWidth={10} styles={progressStyles} />
          </div>
        );

      default:
        return <img src={playIcon} />;
    }
  };

  return (
    <IconButton
      disabled={status === AudioFileStatus.loading}
      onClick={onClick}
      className={classNames(styles.playButton, {
        [styles.primary]: appearance === 'primary',
        [styles.secondary]: appearance === 'secondary',
      })}
    >
      {renderIcon()}
    </IconButton>
  );
};
