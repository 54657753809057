import { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: 'xs' | 'sm' | 'md' | 'default';
  theme?: 'default' | 'white' | 'darker' | 'transparent';
};

export const RoundButton = (props: Props) => {
  const {
    size = 'default',
    theme = 'default',
    children,
    className: externalStyles,
    ...btnProps
  } = props;

  return (
    <div className={classNames(styles.container, externalStyles)}>
      <button className={classNames(styles.button, styles[theme], styles[size])} {...btnProps}>
        {children}
      </button>
    </div>
  );
};
