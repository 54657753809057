import { Image } from 'modules/components/image';
import { useGetShardUri } from 'modules/hooks';
import { AnimatedGift } from 'modules/components/animated-gift';

import styles from './index.module.scss';

type Props = {
  basename: string;
  isAnimated?: boolean;
};

export const GiftMessage = (props: Props) => {
  const { basename, isAnimated } = props;
  const getShardUri = useGetShardUri();

  if (isAnimated) {
    return <AnimatedGift animationPath={basename} />;
  }

  return (
    <Image
      a11yLabel="message-cheer"
      className={styles.chatGiftMessage}
      src={getShardUri(basename)}
      alt="gift-image"
    />
  );
};
