import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from 'modules/api';
import {
  NotifyAboutTheTrapPayload,
  GetAutoGeneratedMessageAnswersPayload,
} from 'modules/api/payload';
import { useLogger } from 'modules/services';
import { useOperatorId } from 'modules/domain/auth/hooks';

import {
  setCopilotData,
  resetCopilotData,
  setCopilotDataLoadingStart,
  setCopilotDataLoadingFailed,
  CopilotOption,
  mapAutoGeneratedAnswersDto,
} from '../model';
import { getCopilotDataId } from '../helpers';

export type GetCopilotDataPayload = GetAutoGeneratedMessageAnswersPayload;

export type { NotifyAboutTheTrapPayload };

export function useCopilotApi() {
  const dispatch = useDispatch();
  const operatorId = useOperatorId();
  const { dialogsMessagesAutomation: dialogsMessagesAutomationApi } = useApi();
  const { log } = useLogger('useCopilotApi');

  const loadCopilotData = useCallback(
    async (animatorId: string, attendeeId: string, messageId: number) => {
      dispatch(resetCopilotData());

      const dataId = getCopilotDataId(animatorId, attendeeId, messageId);

      const payload: GetCopilotDataPayload = { operatorId, animatorId, attendeeId, messageId };

      try {
        dispatch(setCopilotDataLoadingStart({ dataId }));

        const copilotDto =
          await dialogsMessagesAutomationApi.getAutoGeneratedMessageAnswers(payload);
        const copilotData = mapAutoGeneratedAnswersDto(copilotDto);

        dispatch(setCopilotData({ dataId, copilotData }));
        log('info', 'Received copilot options', { copilotData, payload });
      } catch (error) {
        log('error', 'Failed to load copilot options', { error, payload });

        dispatch(setCopilotDataLoadingFailed({ dataId }));
      }
    },
    [operatorId, dialogsMessagesAutomationApi, log, dispatch],
  );

  const notifyAboutTheTrap = useCallback(
    async (animatorId: string, attendeeId: string, option: CopilotOption) => {
      const payload: NotifyAboutTheTrapPayload = {
        animatorId,
        attendeeId,
        operatorId,
        trapText: option.text,
      };

      try {
        log('info', 'Notify about the copilot trap', { payload, option });
        await dialogsMessagesAutomationApi.notifyAboutTheTrap(payload);
      } catch (error) {
        log('error', 'Failed to notify about the copilot trap', { error, payload });
      }
    },
    [dialogsMessagesAutomationApi, log, operatorId],
  );

  return {
    loadCopilotData,
    notifyAboutTheTrap,
  };
}
