import { useCallback, useEffect, useRef } from 'react';

import { TypingSource, useTypingTracker } from 'modules/services';

const TYPING_END_DELAY = 5000;

export const useChatInputTypingTrackerHandler = (dialogId: string, sendBtnKey: string) => {
  const typingTracker = useTypingTracker();

  const timeout = useRef<number | undefined>();

  const setTypingEnd = useCallback(() => {
    clearTimeout(timeout.current);

    typingTracker.setTypingEnd(dialogId, TypingSource.ChatInput);
  }, [typingTracker, dialogId]);

  const setTypingStart = useCallback(() => {
    clearTimeout(timeout.current);

    typingTracker.setTypingStart(dialogId, TypingSource.ChatInput);

    timeout.current = window.setTimeout(() => {
      setTypingEnd();
    }, TYPING_END_DELAY);
  }, [typingTracker, dialogId, setTypingEnd]);

  useEffect(
    () => () => {
      setTypingEnd();
    },
    [setTypingEnd],
  );

  const handleTypingChange = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === sendBtnKey) {
        setTypingEnd();
      } else {
        setTypingStart();
      }
    },
    [setTypingStart, setTypingEnd, sendBtnKey],
  );

  return handleTypingChange;
};
