import { memo } from 'react';
import cn from 'classnames';

import { KPI_VALUE_EFFECTS } from 'modules/domain/earnings/constants';
import { testId } from 'modules/utils/test-id';

import styles from './index.module.css';

export const KPIExplanationBlock = memo(() => {
  return (
    <div className={styles.container}>
      <div className={styles.legend}>
        KPI Effect has the potential to either increase or decrease your income from all Replies.
        The greater the number of tasks you successfully complete by the end of each week, the more
        additional income you will receive.
      </div>
      <div className={styles.table}>
        <div className={styles.column}>
          <div className={styles.firstRow}>Total KPI</div>
          <div className={styles.secondRow}>KPI Effect</div>
        </div>
        {KPI_VALUE_EFFECTS.map((kpiRecord, index) => {
          return (
            <div className={styles.column} key={kpiRecord.kpiValue}>
              <div
                className={cn(
                  styles.firstRow,
                  styles.leftBorder,
                  kpiRecord.kpiValue <= 0 ? styles.badValue : styles.goodValue,
                )}
                {...testId(`kpi-explanation-block-kpi-value-${index}`)}
              >
                {kpiRecord.kpiValue}
              </div>
              <div
                className={cn(styles.secondRow, styles.leftBorder)}
                {...testId(`kpi-explanation-block-kpi-effect-${index}`)}
              >
                {kpiRecord.kpiEffect}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});
