import { useState, useEffect } from 'react';

import { useApi } from 'modules/api';
import { parseDialogId } from 'modules/utils';
import { useLogger } from 'modules/services';

import { useIcebreakersAvailable } from './use-icebreakers-available';

export function useIcebreakersEnabled(dialogId: string) {
  const icebreakersAvailable = useIcebreakersAvailable(dialogId);

  const [icebreakersEnabled, setIcebreakersEnabled] = useState<boolean>(false);

  const { features: featuresApi } = useApi();
  const { logError } = useLogger('useIcebreakersEnabled');

  useEffect(() => {
    if (!icebreakersAvailable) {
      return;
    }

    const { attendeeId } = parseDialogId(dialogId);

    featuresApi
      .getIcebreakersEnabled(attendeeId)
      .then(result => setIcebreakersEnabled(result.enabled))
      .catch(error => {
        logError('Failed to get icebreakers availability', { error, attendeeId });
      });
  }, [icebreakersAvailable, featuresApi, logError, dialogId]);

  return icebreakersEnabled;
}
