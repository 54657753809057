import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxStoreProvider } from 'react-redux';

import { App } from './modules/App';
import { store } from './modules/store';
import { AppContextProvider, useAppContextInitializer } from './modules/domain/app-context';
import { initDatadog, initSentry } from './modules/services';
import './index.scss';

initSentry();
initDatadog();

const Root = () => {
  const appContext = useAppContextInitializer();

  if (!appContext) {
    return null;
  }

  return (
    <AppContextProvider value={appContext}>
      <ReduxStoreProvider store={store}>
        <App />
      </ReduxStoreProvider>
    </AppContextProvider>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <StrictMode>
    <Root />
  </StrictMode>,
);
