import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { useApi } from 'modules/api';
import { useLogger } from 'modules/services';
import { setUserTags, setUserExperimentTags } from 'modules/domain/users/model';

export const useUserTagsApi = () => {
  const dispatch = useDispatch();
  const { usersTags: usersTagsApi } = useApi();
  const { logError } = useLogger('useUserTagsApi');

  const loadUserTags = useCallback(
    async (userId: string) => {
      try {
        const tags = await usersTagsApi.getUserTags(userId);
        dispatch(setUserTags({ userId, tags }));
      } catch (error) {
        logError('fetchUserTags error', { userId, error });
      }
    },
    [dispatch, usersTagsApi, logError],
  );

  const loadUserExperimentsTags = useCallback(
    async (userId: string) => {
      try {
        const tags = await usersTagsApi.getUserExperimentsTags(userId);
        dispatch(setUserExperimentTags({ userId, tags }));
      } catch (error) {
        logError('setUserExperimentTags error', { userId, error });
      }
    },
    [dispatch, usersTagsApi, logError],
  );

  return {
    loadUserTags,
    loadUserExperimentsTags,
  };
};
