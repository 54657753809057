import { InputHTMLAttributes } from 'react';
import { FieldAttributes, FormikState, FormikValues } from 'formik';
import classNames from 'classnames';

import styles from './index.module.scss';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  field: FieldAttributes<any>;
  form: FormikState<FormikValues>;
  backgroundTheme?: 'light' | 'dark';
  className: string;
};

export const TextInput = (props: Props) => {
  const {
    field,
    form: { errors, touched },
    backgroundTheme = 'dark',
    className: externalClassName,
    ...inputProps
  } = props;
  const { name: fieldName } = field;

  return (
    <input
      className={classNames(
        styles.container,
        {
          [styles.containerDark]: backgroundTheme === 'dark',
          [styles.required]: errors[fieldName] && touched[fieldName],
        },
        externalClassName,
      )}
      {...field}
      {...inputProps}
    />
  );
};
