import { testId } from 'modules/utils';
import { RoundButton } from 'modules/components/common/button/round';
import { useDialogsSearch } from 'modules/domain/dialog/hooks';

import styles from './index.module.css';

type Props = {
  animatorId?: string;
  attendeeId?: string;
};

export function DialogSearchIndicator(props: Props) {
  const { resetFoundDialog } = useDialogsSearch();
  const { animatorId, attendeeId } = props;

  return (
    <div className={styles.container}>
      <div className={styles.ids}>
        <div {...testId('search-mode-client-id')}>Client’s ID: {attendeeId}</div>
        <div {...testId('search-mode-animator-id')}>Animator’s ID: {animatorId}</div>
      </div>
      <div className={styles.closeButton}>
        <RoundButton
          {...testId('return-to-badges')}
          size="md"
          theme="darker"
          onClick={resetFoundDialog}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path d="M4 4L12 12" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 4L4 12" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </RoundButton>
      </div>
    </div>
  );
}
