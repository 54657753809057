import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from 'modules/api';
import { DictionaryName } from 'modules/api/dto';
import { useLogger } from 'modules/services';

import { setDictionary } from '../model';

const dictionariesList = Object.values(DictionaryName);

export const useDictionaryApi = () => {
  const dispatch = useDispatch();
  const { dictionaries: dictionariesApi } = useApi();
  const { logError } = useLogger('useDictionaryApi');

  const loadDictionary = useCallback(
    async (dictionaryName: DictionaryName, language: string) => {
      try {
        const dictionary = await dictionariesApi.getDictionary(dictionaryName, language);

        dispatch(setDictionary({ dictionaryName, dictionary }));
      } catch (error) {
        logError('fetchDictionary error', { language, dictionaryName, error });
      }
    },
    [dispatch, dictionariesApi, logError],
  );

  const loadAllDictionaries = useCallback(
    async (language: string) => {
      await Promise.all(
        dictionariesList.map(dictionaryName => loadDictionary(dictionaryName, language)),
      );
    },
    [loadDictionary],
  );

  return {
    loadAllDictionaries,
  };
};
