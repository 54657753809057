import { TextareaHTMLAttributes } from 'react';
import { FieldAttributes, FormikState, FormikValues } from 'formik';
import classNames from 'classnames';

import styles from './index.module.scss';

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  field: FieldAttributes<any>;
  form: FormikState<FormikValues>;
  className?: string;
};

export const MessageInput = (props: Props) => {
  const {
    field,
    form: { errors, touched },
    className: customStyles,
    ...textAreaProps
  } = props;
  const { name: fieldName } = field;

  return (
    <textarea
      className={classNames(styles.container, customStyles, {
        [styles.required]: errors[fieldName] && touched[fieldName],
      })}
      {...field}
      {...textAreaProps}
    />
  );
};
