import { PropsWithChildren } from 'react';

import { useChatScroller } from './hooks';
import { ChatScrollerContext } from './context';

export const ChatScrollerContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const chatScroller = useChatScroller();

  return (
    <ChatScrollerContext.Provider value={chatScroller}>{children}</ChatScrollerContext.Provider>
  );
};
