import { useCallback, useEffect } from 'react';

import { useDialogLoadApi } from 'modules/domain/dialog/hooks';
import { useOperatorId } from 'modules/domain/auth/hooks';
import { useWsApi } from 'modules/api';
import { ApiEventsType } from 'modules/api/dto';

export const useDialogAutoSwitcher = (animatorId: string, attendeeId: string) => {
  const operatorId = useOperatorId();
  const { loadNextDialog } = useDialogLoadApi();
  const { lastMessage } = useWsApi();

  const switchDialog = useCallback(() => loadNextDialog(operatorId), [loadNextDialog, operatorId]);

  useEffect(() => {
    if (lastMessage?.type === ApiEventsType.DialogOperatorLockUpdated) {
      const { animatorId: justLockedChatAnimatorId, clientId: justLockedChatAttendeeId } =
        lastMessage.payload;

      if (justLockedChatAttendeeId === attendeeId && justLockedChatAnimatorId === animatorId) {
        switchDialog();
      }
    }
  }, [animatorId, attendeeId, lastMessage, switchDialog]);
};
