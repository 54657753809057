import { memo, useCallback, useEffect, useState } from 'react';

import { DialogMessage } from 'modules/domain/messages/model';
import { useShortcutListener } from 'modules/components/common/hooks/use-shortcut-listener';
import { testId } from 'modules/utils/test-id';
import { useCopilotData, useCopilotHandlers } from 'modules/domain/copilot/hooks';
import { checkIsTrapOption } from 'modules/domain/copilot/helpers';
import { useChatScrollerActions } from 'modules/components/chat/chat-scroller-context';

import { useOnChatInputFocused } from './hooks';
import { CopilotOption } from './option';
import { CopilotSpecialOption } from './special-option';
import { Skeleton } from './skeleton';
import styles from './styles.module.scss';

type Props = {
  attendeeId: string;
  animatorId: string;
  lastMessage: DialogMessage;
};

export const CopilotAnswerOptions = memo((props: Props) => {
  const { attendeeId, animatorId, lastMessage } = props;

  const { scrollToBottom } = useChatScrollerActions();

  const { selectCopilotTextOption, notifyAboutTheTrap } = useCopilotHandlers(
    animatorId,
    attendeeId,
  );
  const copilotData = useCopilotData(animatorId, attendeeId, lastMessage);
  const { options: copilotOptions, specialOption, loading } = copilotData;

  const [trapModalVisible, setTrapModalVisible] = useState(false);

  const closeTrapModal = useCallback(() => setTrapModalVisible(false), []);

  useEffect(() => {
    closeTrapModal();

    if (copilotOptions.length) {
      scrollToBottom();
    }
  }, [closeTrapModal, copilotOptions, scrollToBottom]);

  useEffect(() => {
    if (trapModalVisible) {
      scrollToBottom();
    }
  }, [trapModalVisible, scrollToBottom]);

  useOnChatInputFocused(closeTrapModal);

  const handleCopilotOptionSelect = useCallback(
    (optionIndex: number) => {
      const currentCopilotOption = copilotOptions[optionIndex];

      if (checkIsTrapOption(currentCopilotOption)) {
        setTrapModalVisible(true);
        notifyAboutTheTrap(currentCopilotOption);
        return;
      }

      selectCopilotTextOption(currentCopilotOption, copilotData);
    },
    [copilotOptions, selectCopilotTextOption, notifyAboutTheTrap, copilotData],
  );

  useShortcutListener(keyNumber => handleCopilotOptionSelect(keyNumber - 1), 'altKey');

  if (loading) {
    return <Skeleton />;
  }

  if (!copilotOptions.length) {
    return null;
  }

  return (
    <div className={styles.optionsList} {...testId('copilot-options-list-populated')}>
      {specialOption && (
        <CopilotSpecialOption
          type={specialOption.type}
          animatorId={animatorId}
          attendeeId={attendeeId}
        />
      )}
      {copilotOptions.map((option, index) => (
        <CopilotOption
          key={option.id}
          text={option.text}
          secondShortcutPart={index + 1}
          onClick={() => handleCopilotOptionSelect(index)}
          /* we assume that we have only one trap at the moment, so we don't need to think what the option that activated trap*/
          trapModalVisible={trapModalVisible && checkIsTrapOption(option)}
          onCloseTrapModal={closeTrapModal}
        />
      ))}
    </div>
  );
});
