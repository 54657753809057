import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from 'modules/api';
import { parseDialogId } from 'modules/utils';
import { useLogger } from 'modules/services';

import {
  setDialogMediaState,
  DialogMedia,
  mapChatMediaListDto,
  mapSentMediaListDto,
} from '../model';

export const useDialogMediaApi = () => {
  const dispatch = useDispatch();
  const { dialogsMedia: dialogsMediaApi, usersMedia: usersMediaApi } = useApi();
  const { logError } = useLogger('useChatMediaApi');

  const loadDialogMediaState = useCallback(
    async (dialogId: string) => {
      const { animatorId, attendeeId } = parseDialogId(dialogId);

      try {
        const { sent: sentDto, result: enabled } = await dialogsMediaApi.getDialogMediaState(
          animatorId,
          attendeeId,
        );

        const sent = mapSentMediaListDto(
          usersMediaApi.getUserPhotosUrl(animatorId),
          dialogsMediaApi.getDialogMediaBaseUrl(attendeeId, animatorId),
          sentDto,
        );

        let available: DialogMedia[] = [];

        if (enabled) {
          const animatorChatPhotosDto = await usersMediaApi.getUserChatPhotos(animatorId);

          const availableAnimatorChatPhotosDto = animatorChatPhotosDto.filter(
            animatorPhotoBasename =>
              !sent.some(sentPhoto => sentPhoto.basename === animatorPhotoBasename),
          );

          available = mapChatMediaListDto(
            usersMediaApi.getUserPhotosUrl(animatorId),
            availableAnimatorChatPhotosDto,
          );
        }

        dispatch(
          setDialogMediaState({
            dialogId,
            media: { available, enabled, sent },
          }),
        );
      } catch (error) {
        logError('Failed to get chat media state', { error, animatorId, attendeeId });
      }
    },
    [dispatch, logError, usersMediaApi, dialogsMediaApi],
  );

  return {
    loadDialogMediaState,
  };
};
