import { IHttpTransport } from 'modules/api/transport';

import { IOperatorsFastAnswersDomain } from './contracts';
import { FastAnswersDto, FastAnswersResponseDto } from './dto';

export class OperatorsFastAnswersDomain implements IOperatorsFastAnswersDomain {
  constructor(
    private readonly transport: IHttpTransport,
    private readonly realm: string,
  ) {}

  getFastAnswers(operatorId: string): Promise<FastAnswersDto> {
    return this.transport
      .get<FastAnswersResponseDto>(`/multichat/operators/${operatorId}/fast-answers`)
      .then(response => response.data[this.realm] || {});
  }

  updateFastAnswers(operatorId: string, payload: FastAnswersDto): Promise<void> {
    return this.transport
      .patch<void>(`/multichat/operators/${operatorId}/fast-answers`, { [this.realm]: payload })
      .then(response => response.data);
  }
}
