import { memo } from 'react';

import { UserTag } from 'modules/api/dto';
import { useCurrentDialogChatStartTimestamp } from 'modules/domain/dialog/hooks';
import { useUserField, useUserHasTag } from 'modules/domain/users/hooks';
import { useIsExplicitPossibleForClient } from 'modules/domain/preferences/hooks';
import { getDialogId } from 'modules/utils';

import { LabelName } from '../label-item/constants';
import { LabelItemProps, LabelsList } from '../labels-list';

import { useIsAttendeeFirstChat } from './use-is-attendee-first-chat';

type Props = {
  attendeeId: string;
  animatorId: string;
};

export const AttendeeLabels = memo((props: Props) => {
  const { attendeeId, animatorId } = props;
  const dialogId = getDialogId(animatorId, attendeeId);

  const vipEnabled = useUserHasTag(attendeeId, UserTag.CreditsAccountsVip);
  const attendeeTimezone = useUserField(attendeeId, 'timezone');
  const isFirstChat = useIsAttendeeFirstChat(attendeeId, animatorId);
  const isExplicitPossibleForClient = useIsExplicitPossibleForClient(dialogId);
  const chatStartTimestamp = useCurrentDialogChatStartTimestamp();

  const attendeeLabels: LabelItemProps[] = [
    {
      id: LabelName.CurrentTime,
      enabled: attendeeTimezone !== undefined,
      textTemplateParam: attendeeTimezone,
    },
    {
      id: LabelName.FirstMessageDate,
      enabled: !!chatStartTimestamp,
      textTemplateParam: chatStartTimestamp,
    },
    {
      id: LabelName.VIP,
      enabled: vipEnabled,
    },
    {
      id: LabelName.Sexting,
      enabled: !!isExplicitPossibleForClient,
    },
    {
      id: LabelName.FirstChat,
      enabled: isFirstChat,
    },
  ];

  return <LabelsList labels={attendeeLabels} />;
});
