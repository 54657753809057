import { useMemo } from 'react';

import { useCopilotDataState } from './selectors';

export function useCopilotLongestOptionTextLength(
  animatorId: string,
  attendeeId: string,
  lastMessageId: number,
) {
  const copilotDataState = useCopilotDataState(animatorId, attendeeId, lastMessageId);

  const longestOptionTextLength = useMemo(() => {
    let longestTextLength = 0;

    if (copilotDataState?.options?.length) {
      longestTextLength = copilotDataState.options.reduce((result, option) => {
        return Math.max(result, option.text.length);
      }, longestTextLength);
    }
    return longestTextLength;
  }, [copilotDataState?.options]);

  return longestOptionTextLength;
}
