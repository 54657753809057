import { useLayoutEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

import { useCurrentDialogId } from 'modules/domain/dialog/hooks';
import { useDialogMessages } from 'modules/domain/messages/hooks';
import { DialogMessage } from 'modules/domain/messages/model';

// TODO: refactor the hook. It is overcomplicated
export function useHeaderTimer(onTimerStopped?: (lastTime: string) => void): string {
  const dialogId = useCurrentDialogId();
  const messages = useDialogMessages(dialogId);
  const [timer, setTimer] = useState('00:00');
  const timerState = useRef<{ time?: dayjs.Dayjs; isActive: boolean }>({ isActive: false });
  const prevMessageData = useRef<DialogMessage | undefined>();

  const dialogMessagesCount = messages?.length;
  const lastMessage = messages?.[0];

  useLayoutEffect(() => {
    // we should insure that we have more than 1 message
    if (!dialogMessagesCount) {
      prevMessageData.current = undefined;

      return;
    }
    // initialize prevMessageData
    if (lastMessage?.tag && typeof prevMessageData.current === 'undefined') {
      prevMessageData.current = lastMessage;
    }

    // if we derived new message
    if (lastMessage?.tag && lastMessage.tag !== prevMessageData.current?.tag) {
      if (lastMessage.outgoing) {
        const { isActive: prevIsActive } = timerState.current;

        timerState.current = { isActive: false, time: dayjs() };

        setTimer(lastTime => {
          if (typeof onTimerStopped === 'function' && prevIsActive) {
            onTimerStopped(lastTime);
          }

          return '00:00';
        });

        prevMessageData.current = lastMessage;

        return undefined;
      } else {
        if (!timerState.current.isActive) {
          timerState.current = { isActive: true, time: dayjs().add(1, 'second') };
        } else {
          timerState.current = { isActive: true, time: dayjs() };
        }
        prevMessageData.current = lastMessage;
      }
    }
    prevMessageData.current = lastMessage;
  }, [dialogMessagesCount, lastMessage, onTimerStopped]);

  useLayoutEffect(() => {
    let timerId: NodeJS.Timeout;

    if (timerState.current.isActive) {
      timerId = setInterval(() => {
        if (!timerState.current.isActive) {
          clearInterval(timerId);
        }

        const dif = dayjs().diff(timerState.current.time, 'milliseconds');

        setTimer(dayjs(dif).format('mm:ss'));
      }, 1000);
    }
    return () => {
      clearInterval(timerId);
    };
  }, [timerState.current.isActive]);

  useLayoutEffect(() => {
    if (dialogId) {
      timerState.current = { isActive: true, time: dayjs() };
    }
  }, [dialogId]);

  return timer;
}
