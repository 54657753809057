import { operatorModel } from './model';

const { actions, selectors } = operatorModel;

export const {
  setOperatorInfo,
  setOperatorCommissionInfo,
  setOperatorNeedOnboarding,
  setDialogSearchEnabled,
} = actions;
export const {
  getOperatorInfo,
  getOperatorCommissionInfo,
  getOperatorNeedOnboarding,
  getDialogSearchEnabled,
} = selectors;

export type { OperatorInfo, OperatorCommissionInfo, OperatorModelState } from './types';

export { operatorModel as operatorSlice };
