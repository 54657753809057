import { useCallback } from 'react';

import { useApi } from 'modules/api';
import { useLogger } from 'modules/services';
import { useDispatchAction } from 'modules/hooks/redux';

import { setUserInterests as setUserInterestsAction } from '../model';

export const useUserInterestsApi = () => {
  const setUserInterests = useDispatchAction(setUserInterestsAction);
  const { users: usersApi } = useApi();
  const { logError } = useLogger('useUserInterestsApi');

  const loadUserInterests = useCallback(
    async (userId: string) => {
      try {
        const interests = await usersApi.getUserInterests(userId);
        setUserInterests({ userId, interests });
      } catch (error) {
        logError('fetchUserInterests error', { userId, error });
      }
    },
    [usersApi, setUserInterests, logError],
  );

  return {
    loadUserInterests,
  };
};
