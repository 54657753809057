import { ComponentProps } from 'react';

import { useUserField } from 'modules/domain/users/hooks';
import { TextMessage } from 'modules/components/chat/text-message';

import styles from './index.module.scss';

type Props = Omit<ComponentProps<typeof TextMessage>, 'a11yLabel' | 'className'> & {
  attendeeId: string;
};

export const UnansweredUnpaidMessage = (props: Props) => {
  const { dialogId, messageId, attendeeId, content, outgoing } = props;

  const attendeeName = useUserField(attendeeId, 'name') ?? '';

  return (
    <div className={styles.textMessageContainer}>
      <p className={styles.titleText}>{attendeeName} failed to tell you:</p>
      <TextMessage
        a11yLabel="unanswered-unpaid-message"
        dialogId={dialogId}
        messageId={messageId}
        content={content}
        className={styles.textMessage}
        outgoing={outgoing}
      />
      <p className={styles.descriptionText}>
        Try to touch upon a similar topic to gain User’s attention!
      </p>
    </div>
  );
};
