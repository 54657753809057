import { memo, useCallback } from 'react';
import cn from 'classnames';

import {
  useCurrentDialog,
  useCurrentDialogId,
  useDialogLoadApi,
} from 'modules/domain/dialog/hooks';
import { useOperatorId } from 'modules/domain/auth/hooks';
import { Button } from 'modules/components/common/button/base';
import { testId } from 'modules/utils/test-id';

import { useChatSwitcher } from './hooks/use-chat-switcher';
import { useCanSwitchDialog } from './hooks/use-can-switch-dialog';
import styles from './index.module.scss';
import { useNextChatBtnVisible } from './hooks';

export const NextChatButton = memo(() => {
  const operatorId = useOperatorId();
  const { loadNextDialog } = useDialogLoadApi();

  const dialog = useCurrentDialog();
  const dialogId = useCurrentDialogId();
  const canSwitchDialog = useCanSwitchDialog(dialogId);
  const btnVisible = useNextChatBtnVisible();

  const goToNextChat = useCallback(() => {
    loadNextDialog(operatorId, dialog?.serverId);
  }, [operatorId, loadNextDialog, dialog?.serverId]);

  useChatSwitcher({
    canUseNextChatHotkey: btnVisible && canSwitchDialog,
    action: goToNextChat,
  });

  if (!btnVisible) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Button
        {...testId('next-chat-button')}
        type="button"
        size="xs"
        theme="green"
        className={cn(styles.button, { [styles.disabledButtonSecondStyle]: !canSwitchDialog })}
        onClick={goToNextChat}
        disabled={!canSwitchDialog}
      >
        Next Chat
      </Button>
      <span className={styles.label}>Press Tab</span>
    </div>
  );
});
