import { memo } from 'react';

import { UserChatRole } from 'modules/constants';
import { AccordionTabs } from 'modules/components/accordion-tabs';

import { UserLabels } from './labels';
import styles from './index.module.scss';

type Props = {
  type: UserChatRole;
  animatorId: string;
  attendeeId: string;
};

export const UserPanel = memo((props: Props) => {
  const { animatorId, attendeeId, type } = props;

  return (
    <div className={styles.container}>
      <div className={styles.userContainer}>
        <UserLabels animatorId={animatorId} attendeeId={attendeeId} type={type} />
      </div>
      <AccordionTabs animatorId={animatorId} attendeeId={attendeeId} type={type} />
    </div>
  );
});
