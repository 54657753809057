import { useCallback } from 'react';

import { useDialogsSearch } from 'modules/domain/dialog/hooks';
import { useModalActions } from 'modules/domain/modals/hooks';

import { DialogsSearchForm } from './form';

export const DialogSearchModal = () => {
  const { searchDialog } = useDialogsSearch();
  const { closeModal } = useModalActions();

  const handleFormSubmit = useCallback(
    async (attendeeId: string, animatorId: string) => {
      await searchDialog(attendeeId, animatorId);
      closeModal();
    },
    [searchDialog, closeModal],
  );

  return <DialogsSearchForm onSubmit={handleFormSubmit} onClickClose={closeModal} />;
};
