import { ChatWrapper as Chat } from 'modules/components/chat/wrapper';
import { Loader } from 'modules/components/loader';
import { Dialog } from 'modules/domain/dialog/model';

import styles from './styles.module.scss';

type Props = {
  noNewChats: boolean;
  dialog: Dialog | null;
};

export const ChatWithEmptyStates = (props: Props) => {
  const { noNewChats, dialog } = props;

  if (noNewChats) {
    return (
      <div className={styles.finishedDialogsWrapper}>
        You have replied in all chats. Update the queue pressing Next for the new ones in a few
        minutes.
      </div>
    );
  }

  if (!dialog) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader />
      </div>
    );
  }

  return <Chat currentDialog={dialog} />;
};
