import { useRef } from 'react';

import { DialogMessage } from 'modules/domain/messages/model';
import { VipCaption, ChatDropzone } from 'modules/components/chat';
import { Loader } from 'modules/components/loader';
import { useDialogSomeMessagesLoaded } from 'modules/domain/messages/hooks';

import { ScrollToBottomButton } from './scroll-to-bottom-button';
import { CopilotAnswerOptions } from './copilot-answer-options';
import { ChatScrollContainer } from './scroll-container';
import { ChatMessagesList } from './messages-list';
import styles from './styles.module.scss';

type Props = {
  messages: DialogMessage[];
  animatorId: string;
  attendeeId: string;
  currentDialogId: string;
};

export const CHAT_LOG_CLASS_NAME = styles.chatLog;

export const ChatLog = (props: Props) => {
  const { messages, currentDialogId, animatorId, attendeeId } = props;

  const lastMessage: DialogMessage | undefined = messages[0];

  const someMessagesLoaded = useDialogSomeMessagesLoaded(currentDialogId);

  const chatLogContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div className={CHAT_LOG_CLASS_NAME} ref={chatLogContainerRef}>
      <ChatDropzone
        dialogId={currentDialogId}
        width={chatLogContainerRef.current?.clientWidth || 0}
        height={chatLogContainerRef.current?.clientHeight || 0}
      />
      <ChatScrollContainer>
        {!someMessagesLoaded && <Loader />}
        <ChatMessagesList
          messages={messages}
          currentDialogId={currentDialogId}
          animatorId={animatorId}
        />
        <VipCaption attendeeId={attendeeId} />
        {lastMessage && (
          <CopilotAnswerOptions
            attendeeId={attendeeId}
            animatorId={animatorId}
            lastMessage={lastMessage}
          />
        )}
        <ScrollToBottomButton />
      </ChatScrollContainer>
    </div>
  );
};
