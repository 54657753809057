import { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  theme?: 'default' | 'green' | 'raspberry';
  size?: 'default' | 'xs' | 'sm' | 'md' | 'lg';
};

export const Button = (props: Props) => {
  const {
    theme = 'default',
    size = 'default',
    children,
    className: externalStyles,
    ...btnProps
  } = props;

  return (
    <button
      type="button"
      className={classNames(styles.container, styles[theme], styles[size], externalStyles)}
      {...btnProps}
    >
      {children}
    </button>
  );
};
