import classNames from 'classnames';

import styles from './styles.module.scss';

export type CategoryItem = string;

type Props = {
  categories: CategoryItem[];
  selected: CategoryItem;
  onSelect: (item: CategoryItem) => void;
};

export const AudioCategoriesSidebar = (props: Props) => {
  const { categories, selected, onSelect } = props;

  return (
    <div className={styles.sidebar}>
      <div className={styles.inner}>
        {categories.map(item => (
          <button
            className={classNames(styles.item, {
              [styles.selected]: item === selected,
            })}
            key={item}
            onClick={() => onSelect(item)}
          >
            {item}
          </button>
        ))}
      </div>
    </div>
  );
};
