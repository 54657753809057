import { UserChatRole } from 'modules/constants';

import { AnimatorLabels } from './components/animator-labels';
import { AttendeeLabels } from './components/attendee-labels';

type Props = {
  type: UserChatRole;
  animatorId: string;
  attendeeId: string;
};

export const UserLabels = (props: Props) => {
  const { attendeeId, animatorId, type } = props;

  switch (type) {
    case 'animator':
      return <AnimatorLabels animatorId={animatorId} />;

    default:
      return <AttendeeLabels animatorId={animatorId} attendeeId={attendeeId} />;
  }
};
