import { useCallback } from 'react';

import { useConfig } from 'modules/config';
import { useOperatorId } from 'modules/domain/auth/hooks';

import { log as logBase, LogLevel } from './service';

export const useLogger = (service: string) => {
  const { api } = useConfig();
  const operatorId = useOperatorId();

  const log = useCallback(
    (level: LogLevel, message: string, context?: object) => {
      logBase(level, message, {
        service,
        apiHost: api,
        operatorId,
        ...context,
      });
    },
    [api, operatorId, service],
  );

  const logError = useCallback(
    (message: string, context?: object) => {
      log('error', message, context);
    },
    [log],
  );

  return {
    log,
    logError,
  };
};
