import { useEffect, useState } from 'react';

import { useApi } from 'modules/api';
import { UserTargetedTag } from 'modules/api/dto';
import { useLogger } from 'modules/services';

export const useAttendeeTargetedTags = (attendeeId: string, animatorId: string) => {
  const { usersTags: usersTagsApi } = useApi();
  const { logError } = useLogger('useAttendeeTargetedTags');
  const [tags, setTags] = useState<UserTargetedTag[]>([]);

  useEffect(() => {
    setTags([]);

    usersTagsApi
      .getUserTargetedTags(attendeeId, animatorId)
      .then(setTags)
      .catch(error => {
        logError('Failed to load attendee targeted tags', { animatorId, attendeeId, error });
      });
  }, [usersTagsApi, logError, attendeeId, animatorId]);

  return tags;
};
