import { useStatsContext } from 'modules/components/stats-page/context';
import { AppRoutes } from 'modules/constants';

import { NavButton } from '../nav-button';

import styles from './index.module.css';

export const PageSwitcher = () => {
  const { showKPI } = useStatsContext();

  return (
    <div className={styles.switcher}>
      <NavButton title="Income" url={AppRoutes.Income} />
      {showKPI && <NavButton title="KPI" url={AppRoutes.Kpi} />}
    </div>
  );
};
