import { memo, useEffect } from 'react';

import { testId } from 'modules/utils';
import { useUserHasTag } from 'modules/domain/users/hooks';
import { UserTag } from 'modules/api/dto';

import { useChatScrollerActions } from '../chat-scroller-context';

import styles from './index.module.scss';

type Props = {
  attendeeId: string;
};

export const VipCaption = memo((props: Props) => {
  const { attendeeId } = props;

  const isVip = useUserHasTag(attendeeId, UserTag.CreditsAccountsVip);
  const { scrollToBottom } = useChatScrollerActions();

  useEffect(() => {
    if (isVip) {
      scrollToBottom();
    }
  }, [isVip, scrollToBottom]);

  if (!isVip) {
    return null;
  }

  return (
    <div {...testId('vip-client-label')} className={styles.container}>
      <p className={styles.content}>You&apos;re communicating with a VIP client. Be creative!</p>
    </div>
  );
});
