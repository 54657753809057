import { useCallback } from 'react';

import { useApi } from 'modules/api';
import { getDialogId } from 'modules/utils';
import { useLogger } from 'modules/services';
import { useDispatchAction } from 'modules/hooks/redux';

import {
  mapSpotifyMatchDtoToResult,
  setSpotifyMatchResult as setSpotifyMatchResultAction,
} from '../model';

export const useSpotifyApi = () => {
  const { spotify: spotifyApi } = useApi();
  const { logError } = useLogger('useSpotifyApi');
  const setSpotifyMatchResult = useDispatchAction(setSpotifyMatchResultAction);

  const loadSpotifyMatchResult = useCallback(
    async (animatorId: string, attendeeId: string) => {
      try {
        const [scoreDto, topContentDto] = await Promise.all([
          spotifyApi.getMatchScore(animatorId, attendeeId),
          spotifyApi.getTopContent(attendeeId),
        ]);

        const result = mapSpotifyMatchDtoToResult(scoreDto, topContentDto);
        const dialogId = getDialogId(animatorId, attendeeId);
        setSpotifyMatchResult({ dialogId, result });
      } catch (error) {
        logError('Get spotify match error', { error, animatorId, attendeeId });
      }
    },
    [spotifyApi, logError, setSpotifyMatchResult],
  );

  return { loadSpotifyMatchResult };
};
