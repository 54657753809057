import { useCallback } from 'react';

import { useApi } from 'modules/api';
import { getDialogId } from 'modules/utils';
import { useLogger } from 'modules/services';
import { useOperatorId } from 'modules/domain/auth/hooks';

import { SendComplaintReportData } from '../types';

export const useComplaintApi = () => {
  const operatorId = useOperatorId();
  const { annals } = useApi();
  const { logError } = useLogger('useComplaintApi');

  const sendComplaintReport = useCallback(
    async (data: SendComplaintReportData) => {
      const { complaint, userText, outgoing, senderId, recipientId, content, id: messageId } = data;

      const animatorId = outgoing ? senderId : recipientId;
      const clientId = outgoing ? recipientId : senderId;
      const reason = complaint === 'Other' ? userText : complaint;

      const payload = {
        'user-id': clientId,
        'animator-id': animatorId,
        'operator-id': operatorId,
        'message-from': senderId,
        message: typeof content === 'string' ? content : '',
        reason: reason,
        content: `/multichat/dialogs/messages/${getDialogId(
          animatorId,
          clientId,
        )}/message/${messageId}`,
      };

      try {
        await annals.send(operatorId, 'user-report', payload);
      } catch (error) {
        logError('Failed to send a complaint', { data, payload, error });

        throw error;
      }
    },
    [annals, logError, operatorId],
  );

  return { sendComplaintReport };
};
