import { useCallback } from 'react';

import { useApi } from 'modules/api';
import { useOperatorId } from 'modules/domain/auth/hooks';

export type TrackCopilotUsageData = {
  message: string;
  tag: string;
  type: string;
  options: string[];
  botName?: string;
  selectedOption?: string;
};

export const useCopilotAnalyticsApi = (animatorId: string, attendeeId: string) => {
  const operatorId = useOperatorId();
  const { annals } = useApi();

  const trackCopilotUsage = useCallback(
    ({ selectedOption, ...data }: TrackCopilotUsageData) => {
      const payload = {
        userId: attendeeId,
        animatorId,
        operatorId,
        'selected-option': selectedOption,
        ...data,
      };

      return annals.send(operatorId, 'copilot-data', payload);
    },
    [annals, operatorId, animatorId, attendeeId],
  );

  return { trackCopilotUsage };
};
