import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';

import { ModalNames } from 'modules/domain/modals/model';
import { useModalActions } from 'modules/domain/modals/hooks';
import { testId } from 'modules/utils';
import { IconButton } from 'modules/components/common/button';
import microphoneIcon from 'images/microphone-icon.svg';

type Props = {
  dialogId: string;
};

const TOOLTIP_ID = 'audio-messages-btn-tooltip';
const TOOLTIP_HIDE_DELAY = 3000;

export const AudioSelectorButton = (props: Props) => {
  const { dialogId } = props;

  const [tooltipVisible, setTooltipVisible] = useState(false);

  useEffect(() => {
    setTooltipVisible(true);

    const timeout = setTimeout(() => setTooltipVisible(false), TOOLTIP_HIDE_DELAY);

    return () => clearTimeout(timeout);
  }, [dialogId]);

  const { openModal } = useModalActions();

  const handleClick = () => {
    openModal(ModalNames.AudioSelector);
  };

  return (
    <>
      <IconButton
        data-tooltip-id={TOOLTIP_ID}
        onClick={handleClick}
        {...testId('audio-selector__button')}
      >
        <img src={microphoneIcon} />
      </IconButton>
      <Tooltip
        variant="light"
        noArrow
        place="top-end"
        id={TOOLTIP_ID}
        isOpen={tooltipVisible}
        content="Tap to send Voice Message"
      />
    </>
  );
};
