import { KeyboardEvent, FocusEvent, useEffect, useState } from 'react';

import { CustomNoteValue } from './utils';
import styles from './index.module.scss';

type Props = Omit<CustomNoteValue, 'id'> & {
  onInitialFocus: () => void;
  onBlur: (text: string) => void;
  onEnterPress: (text: string) => void;
  dataId: string;
};

export const NoteValue = (props: Props) => {
  const { text, isAutoChanged, justAdded, onBlur, onInitialFocus, onEnterPress, dataId } = props;

  const [noteRef, setNoteRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (justAdded && noteRef) {
      noteRef.focus();
      onInitialFocus();
    }
  }, [justAdded, noteRef, onInitialFocus]);

  const handleAutoMarkClick = () => {
    noteRef?.focus();
  };

  const handleBlur = (event: FocusEvent<HTMLDivElement>) => {
    onBlur(event.target.innerText);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      onEnterPress((event.target as HTMLDivElement).innerText);
    }
  };

  return (
    <div className={styles.noteRow}>
      {isAutoChanged && (
        <div className={styles.autoMark} onClick={handleAutoMarkClick}>
          🤖
        </div>
      )}
      <div
        data-id={dataId}
        contentEditable
        suppressContentEditableWarning
        ref={setNoteRef}
        className={styles.note}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      >
        {text}
      </div>
    </div>
  );
};
