import { datadogLogs } from '@datadog/browser-logs';

import { clientConfig } from 'modules/config/client';

type LogContext = {
  service?: string;
  apiHost?: string;
  userId?: string;
} & {
  [key: string]: any;
};

export type LogLevel = 'log' | 'info' | 'warn' | 'error';

let initialized = false;

export function init() {
  const { env } = clientConfig;

  if (env === 'debug') {
    // If you want to test datadog logs in debug mode just disable this check
    return;
  }

  datadogLogs.init({
    clientToken: clientConfig.datadog.clientToken,
    forwardErrorsToLogs: false,
    sessionSampleRate: 100,
    env,
    version: clientConfig.revision,
  });

  console.log(`Initialize Datadog with env: ${env}`);

  initialized = true;
}

export const log = (level: LogLevel, message: string, context: LogContext) => {
  console[level](message, context);

  if (!initialized) {
    return;
  }

  const messageContext = {
    host: 'Once Multichat Web',
    ...context,
  };

  if (level === 'error' && context.error) {
    return datadogLogs.logger.error(message, messageContext, context.error);
  }

  return datadogLogs.logger[level](message, messageContext);
};
