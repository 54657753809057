import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from 'modules/api';
import { parseDialogId } from 'modules/utils';
import { useLogger } from 'modules/services';

import {
  setUnansweredUnpaidMessageLoaded,
  insertDialogMessages,
  mapDialogMessagesDto,
  mapDialogMessageDto,
} from '../model';
import { findFirstUnansweredUnpaidTextMessage } from '../helpers';

const UNANSWERED_UNPAID_PAGE_SIZE = 30;

export const useLoadDialogMessagesApi = (dialogId: string) => {
  const dispatch = useDispatch();
  const { dialogsMessages: dialogsMessagesApi } = useApi();
  const { logError } = useLogger('useLoadDialogMessagesApi');

  const loadDialogMessages = useCallback(
    async (count: number, skip: number) => {
      const { animatorId, attendeeId } = parseDialogId(dialogId);

      try {
        const messagesDto = await dialogsMessagesApi.getDialogMessages(animatorId, attendeeId, {
          select: count,
          omit: skip,
        });

        const messages = mapDialogMessagesDto(messagesDto, animatorId);

        const allMessagesLoaded = messages.length < count;

        dispatch(insertDialogMessages({ dialogId, messages, allMessagesLoaded }));
      } catch (error) {
        logError(`Failed to load dialog messages`, { error, dialogId });
      }
    },
    [dialogsMessagesApi, dispatch, dialogId, logError],
  );

  const loadUnansweredUnpaidDialogMessage = useCallback(async () => {
    const { animatorId, attendeeId } = parseDialogId(dialogId);

    try {
      // we switch attendeeId and animatorId here, so that attendeeId is a sender in this case
      const messages = await dialogsMessagesApi.getDialogMessages(attendeeId, animatorId, {
        select: UNANSWERED_UNPAID_PAGE_SIZE,
        omit: 0,
        withUnsent: true,
      });

      const firstUnansweredUnpaidMessageDto = findFirstUnansweredUnpaidTextMessage(
        animatorId,
        attendeeId,
        messages,
      );

      if (firstUnansweredUnpaidMessageDto) {
        const firstUnansweredUnpaidMessage = mapDialogMessageDto(
          firstUnansweredUnpaidMessageDto,
          animatorId,
        );

        dispatch(
          setUnansweredUnpaidMessageLoaded({ dialogId, message: firstUnansweredUnpaidMessage }),
        );
      }
    } catch (error) {
      logError('Failed to loadUnansweredUnpaidDialogMessage', { error, dialogId });
    }
  }, [dialogsMessagesApi, dialogId, logError, dispatch]);

  return {
    loadDialogMessages,
    loadUnansweredUnpaidDialogMessage,
  };
};
