import { useCallback } from 'react';

import { HttpError, useApi } from 'modules/api';
import { toast, useLogger } from 'modules/services';
import { useOperatorApi, useOperatorOnboardingActions } from 'modules/domain/operator/hooks';
import { mapUserDtoToUser } from 'modules/domain/users/model';
import { useAuthContext } from 'modules/domain/auth/hooks';

export const useLoadOperatorData = () => {
  const { users: usersApi } = useApi();
  const { logError } = useLogger('useLoadOperatorData');

  const { clearAuthInfo } = useAuthContext();
  const { actualizeOnboardingStatus } = useOperatorOnboardingActions();
  const { loadOperatorCommissionInfo, loadOperatorInfo, loadDialogSearchEnabled } =
    useOperatorApi();

  const loadOperatorData = useCallback(
    async (operatorId: string) => {
      try {
        if (!operatorId) {
          throw new HttpError('Operator ID is not provided', 404);
        }

        const operatorDto = await usersApi.getUserData(operatorId, true);
        const operator = mapUserDtoToUser(operatorDto);

        loadOperatorInfo(operatorId);
        loadOperatorCommissionInfo(operatorId);
        loadDialogSearchEnabled(operatorId);

        actualizeOnboardingStatus();

        return operator;
      } catch (error) {
        logError('Failed to get operator data', { error, operatorId });

        const httpError = error as HttpError;
        if ([404, 403, 401].includes(httpError.status as number)) {
          clearAuthInfo();
        } else {
          toast.showError(
            'Failed to load operator data. Check your connection and try again later',
          );
        }

        return null;
      }
    },
    [
      actualizeOnboardingStatus,
      clearAuthInfo,
      loadOperatorCommissionInfo,
      loadOperatorInfo,
      logError,
      usersApi,
      loadDialogSearchEnabled,
    ],
  );

  return loadOperatorData;
};
