import { useCallback, useEffect, useState } from 'react';
import { merge } from 'lodash';

import { useLogger } from 'modules/services';
import { HttpTransport } from 'modules/api/transport';

import { clientConfig } from './client';
import { AppConfig, RemoteConfig } from './types';

const transport = new HttpTransport('');

export const useConfigLoader = () => {
  const [config, setConfig] = useState<AppConfig | null>(null);

  const { logError } = useLogger('useConfigLoader');

  const loadConfig = useCallback(async () => {
    try {
      const { data: remoteConfig } = await transport.get<RemoteConfig>(
        `/config/config.${clientConfig.appName}.json`,
      );

      const appConfig = merge({}, clientConfig, remoteConfig);

      setConfig(appConfig);
    } catch (error) {
      logError('Load remote config error', { error });
    }
  }, [logError]);

  useEffect(() => {
    if (!config) {
      loadConfig();
    }
  }, [config, loadConfig]);

  return config;
};
