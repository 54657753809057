import { memo, useMemo } from 'react';

import { useActiveModalData, useModalActions } from 'modules/domain/modals/hooks';
import {
  useDaysWithMessages,
  useCalendarNavigation,
  useScrollToMessagesByDate,
} from 'modules/domain/calendar/hooks';
import { useCurrentDialogId } from 'modules/domain/dialog/hooks';
import { CalendarModalData } from 'modules/constants';
import { utcDate } from 'modules/utils';

import { CalendarWrapper } from './calendar-wrapper';
import { CalendarNavigation } from './calendar-navigation';
import { getTileDisabled, getTileContent } from './helpers';
import styles from './index.module.css';
import './calendar-wrapper/calendar-styles.css';

export const CalendarModal = memo(() => {
  const { closeModal } = useModalActions();
  const { timestamp } = useActiveModalData<CalendarModalData>();

  const initialDate = useMemo(() => utcDate(timestamp), [timestamp]);

  const dialogId = useCurrentDialogId();
  const daysWithMessages = useDaysWithMessages(dialogId);

  const {
    slideMonthLeft,
    slideMonthRight,
    activeMonth,
    canSlideRight,
    canSlideLeft,
    activeMonthFormatted,
  } = useCalendarNavigation(initialDate, daysWithMessages[0]?.date);

  const scrollToMessagesByDate = useScrollToMessagesByDate(daysWithMessages, dialogId);

  const handleDateSelect = (date: Date) => {
    scrollToMessagesByDate(date);
    closeModal();
  };

  return (
    <div className={styles.container}>
      <CalendarNavigation
        onClickPrev={slideMonthLeft}
        onClickNext={slideMonthRight}
        showNextButton={canSlideRight}
        showPrevButton={canSlideLeft}
        currentDate={activeMonthFormatted}
      />
      <CalendarWrapper
        activeStartDate={activeMonth}
        onChange={handleDateSelect}
        tileDisabled={getTileDisabled(daysWithMessages)}
        tileContent={getTileContent(initialDate)}
      />
    </div>
  );
});
